import { EventDetailBlock } from '../EventDetails';
import { Typography } from '@/components/Typography';
import { argoconPage } from '@/utils/contentData/argocon';

import './ArgoCon2021.scss';

const ArgoCon2021 = () => {
  return (
    <>
      <Typography
        className="argocon-2021-header"
        component="h4"
        id="argocon-2021"
        typographyKind="header-secondary-large-bold"
        margin={[7, 0]}
      >
        {argoconPage.argocon2021.title}
      </Typography>

      <div className="justify-space-between mt-1 flex-wrap">
        <EventDetailBlock
          title="Date"
          description={argoconPage.argocon2021.date.description}
        />
        <EventDetailBlock
          title="Location"
          description={argoconPage.argocon2021.location.description}
        />
        <EventDetailBlock
          title="Website"
          url={argoconPage.argocon2021.website.url}
        />
      </div>
    </>
  );
};

export default ArgoCon2021;
