import { EventDetailBlock } from '../EventDetails';
import { TalkListItem } from '../EventSchedule';
import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import Argocon2023 from '@/static/assets/images/ArgoCon/argocon-na-2023.svg';

import './ArgoConNA2023.scss';

export const ArgoConNA2023 = () => {
  return (
    <>
      <Typography
        className="argocon-2021-header"
        component="h4"
        id="argocon-2021"
        typographyKind="header-secondary-large-bold"
        margin={[7, 0]}
      >
        ArgoCon NA 2023
      </Typography>
      <div className="argocon-2023-thumbnail">
        <Image imageUrl={Argocon2023} alt="ArgoCon 2023 NA" />
      </div>
      <div
        className="justify-space-between mt-1 flex-wrap argoconna-2023-event-details"
        style={{ marginBottom: '48px' }}
      >
        <EventDetailBlock title="Date" description="6 November 2023" />
        <EventDetailBlock
          title="Location"
          description="McCormick Place West, Chicago, IL"
        />
        <EventDetailBlock
          title="Website"
          url="https://events.linuxfoundation.org/kubecon-cloudnativecon-north-america/co-located-events/argocon/"
        />

        <EventDetailBlock
          title="CFP"
          url="https://events.linuxfoundation.org/kubecon-cloudnativecon-north-america/co-located-events/cfp-colocated-events/#submit-your-talk"
        />
      </div>

      <Typography
        component="h4"
        typographyKind="header-secondary-large-bold"
        margin={[8, 0]}
      >
        Talks involving Akuity staff
      </Typography>
      <div className={`argocon-2022-day-1-talks`}>
        <ul>
          <TalkListItem
            title="Building a Bridge Between Argo CD and Terraform"
            authors="Carlos Santana, AWS & Nicholas Morey, Akuity"
            scheduleUrl="https://sched.co/1Rj1I"
          />
          <TalkListItem
            title="Sponsored Keynote: Kargo: Expanding GitOps to Change Management"
            authors="Jesse Suen, Akuity"
            scheduleUrl="https://sched.co/1RqHu"
          />
          <TalkListItem
            title="Lessons from Implementing Pull Request Generated Preview Apps at MasterClass"
            authors="Alexander Matyushentsev, Akuity &Paul Phipps, MasterClass"
            scheduleUrl="https://sched.co/1Rj2e"
          />
          <TalkListItem
            title="Hitting the Runway with ArgoCD at American Airlines"
            authors="Christian Hernandez, Akuity & Karl Haworth, American Airlines"
            scheduleUrl="https://sched.co/1Rj4a"
          />
          <TalkListItem
            title="Scaling Multi-cluster Management with Argo CD and ApplicationSets"
            authors="Alexander Matyushentsev, Jesse Suen, Nicholas Morey, Christian Hernandez"
            scheduleUrl="https://sched.co/1Rt3m"
          />
          <TalkListItem
            title="Closing Remarks"
            authors="Christian Hernadez, Akuity"
            scheduleUrl="https://sched.co/1Rnzr"
          />
        </ul>
      </div>
    </>
  );
};
