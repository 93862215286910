import { en } from '@/languages/en';
import HongProfileImage from '@/static/assets/images/AboutUs/about-us-founder--hong.png';
import GitOpsCertification from '@/static/assets/images/ArgoCon/advanced-gitops-certification-workshop.png';
import ArgoCon2022 from '@/static/assets/images/ArgoCon/argocon2022.png';

const language = en;

export const argoconPage = {
  header: language.argoconPage.title,
  subheader: language.argoconPage.subtitle,
  description: language.argoconPage.description,
  originator: {
    image: {
      url: HongProfileImage,
      alt: 'Hong Wang Founder Of Akuity'
    },
    history: language.argoconPage.originator.history,
    name: 'Hong Wang, Argo originator, Akuity CEO'
  },
  eventDetails: {
    title: language.argoconPage.eventDetails.title,
    image: {
      url: ArgoCon2022,
      alt: 'ArgoCon 2022 Thumbnail'
    },
    date: {
      description: language.argoconPage.eventDetails.dates
    },
    location: {
      description: language.argoconPage.eventDetails.location
    },
    website: {
      url: 'https://events.linuxfoundation.org/argocon/'
    },
    schedule: {
      url: 'https://events.linuxfoundation.org/argocon/program/schedule/'
    },
    akuityRole: language.argoconPage.eventDetails.akuityRoleInEvent
  },
  eventSchedule: {
    dayWise: language.argoconPage.eventSchedule.dayWise,
    embedImage: [
      {
        onDay: 2,
        image: {
          url: GitOpsCertification,
          alt: 'Advanced GitOps Certification',
          className: 'advanced-gitops-certification-thumbnail'
        }
      }
    ]
  },
  workshopBenefits: language.argoconPage.workshopBenefits,
  learnHowTo: language.argoconPage.learnHowTo,
  workshopCompletion: language.argoconPage.workshopCompletion,
  cta: {
    ...language.argoconPage.cta,
    url: 'https://events.linuxfoundation.org/argocon/register/'
  },
  argocon2021: {
    ...language.argoconPage.argocon2021,
    website: {
      url: 'https://www.techstrongevents.com/argocon21/'
    }
  }
};
