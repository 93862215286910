import { Typography } from '@/components/Typography';
import { ResourcesArgoConBalck } from '@/static/assets/icons/Navigation';
import { argoconPage } from '@/utils/contentData/argocon';

import './Head.scss';

const HeadSection = () => {
  return (
    <>
      <Typography
        id="argocon"
        component={'h2'}
        typographyKind={'header-primary-large'}
        margin={[4, 0]}
        className="argocon-header"
      >
        <div className="argocon-header-icon">
          <ResourcesArgoConBalck height="50px" width="50px" />
        </div>
        {argoconPage.header}
      </Typography>

      <Typography
        id="vendor-neutral-argo-focused-event"
        component={'p'}
        typographyKind={'text-primary-medium-regular'}
        margin={[2, 0]}
        className="argocon-subheader"
        size={24}
      >
        {argoconPage.subheader}
      </Typography>

      <div className="argocon-header-description">
        {argoconPage.description.map((paragraph) => (
          <Typography
            key={paragraph.slice(0, 15)}
            component={'p'}
            typographyKind={'text-primary-extra-large-medium'}
            margin={[3, 0]}
          >
            {paragraph}
          </Typography>
        ))}
      </div>
    </>
  );
};

export default HeadSection;
