import { EventDetailBlock } from '../EventDetails';
import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import Argocon2023 from '@/static/assets/images/ArgoCon/argocon-2023-eu.svg';

import './ArgoCon2023.scss';

export const ArgoCon2023 = () => {
  return (
    <>
      <Typography
        className="argocon-2021-header"
        component="h4"
        id="argocon-2021"
        typographyKind="header-secondary-large-bold"
        margin={[7, 0]}
      >
        ArgoCon EU 2023
      </Typography>

      <div className="argocon-2023-thumbnail">
        <Image imageUrl={Argocon2023} alt="ArgoCon 2023 EU" />
      </div>

      <div className="justify-space-between mt-1 flex-wrap argocon-2023-event-details">
        <EventDetailBlock title="Date" description="18 April 2023" />
        <EventDetailBlock
          title="Location"
          description="RAI, Amsterdam, The Netherlands"
        />
        <EventDetailBlock
          title="Website"
          url="https://events.linuxfoundation.org/kubecon-cloudnativecon-europe/cncf-hosted-co-located-events/argocon/"
        />

        <EventDetailBlock
          title="Schedule"
          url="https://colocatedeventseu2023.sched.com/overview/type/ArgoCon"
        />
      </div>
    </>
  );
};
