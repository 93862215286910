import Argocon2023 from '@/static/assets/images/ArgoCon/argocon-2023-eu.svg';
import CowbellCaseStudyCoverImage from '@/static/assets/images/seo/case-study-cowbell-social.png';
import WhatnotCaseStudyCoverImage from '@/static/assets/images/seo/case-study-whatnot-social.png';

export const seoConfiguration = {
  home: {
    title: 'Deploy to Kubernetes with Argo CD as a managed service',
    description:
      'Akuity SaaS platform for Argo CD. Argo enterprise support with solution guidance and SLAs. Powered with ❤️ and ☕️ by the Argo Project creators',
    image: ''
  },
  'what-is-argo': {
    title: 'What is Argo?',
    description: `Find out more about the Argo Project and how it solves problems of both application developers and infrastructure engineers.`,
    image: ''
  },
  'security-hardened-argo': {
    title: 'Security-Hardened Argo CD',
    description:
      'A solution designed to supercharge and harden your Argo CD GitOps practices with unmatched security features.',
    image: ''
  },
  about: {
    title: 'About the company',
    description:
      'Find out more about Akuity - the company behind the Akuity Platform and Akuity Enterprise for Argo.',
    image: ''
  },
  akuityCloud: {
    title: 'The Akuity Platform for Argo CD',
    description:
      'Fully-managed platform for Argo CD as a service. Created and maintained by the Argo creators. Sign up for a free trial!',
    image: ''
  },
  akuityEnterprise: {
    title: 'Argo Enterprise services',
    description:
      'With Akuity’s team of Argo experts you get solution guidance, help in putting GitOps best practices to work, compliance and guaranteed enterprise SLAs.',
    image: ''
  },
  contact: {
    title: 'Contact us',
    description:
      'Contact the team behind the Akuity Platform and Akuity Enterprise to see how we can help you scale and safely maintain your Kubernetes deployments.',
    image: ''
  },
  resources: {
    title: 'Argo resources curated by Akuity',
    description:
      'Learn about Argo and GitOps from videos and other resources curated by Akuity.',
    image: ''
  },
  blog: {
    title: 'Akuity blog - Argo CD, Kargo, GitOps, Kubernetes, DevOps',
    description:
      'Knowledge and insights from the Akuity team (the creators of the Argo Project). Learn more about Argo CD, Kargo, Kubernetes, and DevOps best practices.',
    image: ''
  },
  gitOps: {
    title: 'GitOps with Akuity Platform',
    description: 'See how your teams can benefit from implementing GitOps.',
    image: ''
  },
  argocon: {
    title: 'ArgoCon - the Argo Project event sponsored by Akuity.',
    description: `Find out more about Akuity's participation in the biggest Argo-related event.`,
    image: Argocon2023
  },
  'get-demo': {
    title: 'Schedule a technical demo with Akuity.',
    description: `Find out more about Akuity's SaaS platform for Argo with a technical demo from the Akuity team.`,
    image: ''
  },
  pricing: {
    title: 'Akuity Platform pricing & plans',
    description: `Learn more about various tiers that Akuity offers for subscribing to Argo CD as a managed service`,
    image: ''
  },
  'talk-to-sales': {
    title: 'Talk to Akuity sales team',
    description: `Learn more about the pricing that Akuity offers for Argo CD as a managed service and Argo Enterprise services`,
    image: ''
  },
  'what-is-kargo': {
    title:
      'Kargo is a next-generation continuous delivery and application lifecycle orchestration platform for Kubernetes.',
    description: `Kargo builds upon GitOps principles and integrates with Argo CD, to streamline and automate the progressive rollout of changes across the many stages of an application's lifecycle.`,
    image: ``
  },
  'kargo-early-access': {
    title: 'Sign up for Kargo Enterprise',
    description: `Check out the latest open source project from Akuity and take it for a spin`,
    image: ``
  },
  thanks: {
    title: 'Thank you for contacting Akuity',
    description: `Thank you for contacting Akuity - we;ll get back to you as soon as possible`,
    image: ''
  },
  'argo-appreciation': {
    title: 'Akuity Peer Appreciation Program for Argo',
    description:
      'Argo Open Source project has a vibrant community. Nominate your colleague to receive Argo SWAG and one-time GitHub sponsorship.',
    image: ''
  },
  security: {
    title: 'Security and Compliance',
    description:
      'A full and up-to-date list of security and compliance measures taken by Akuity, the enterprise company for Argo.',
    image: ''
  },
  'terms-of-service': {
    title: 'Akuity Platform Terms of Service',
    description:
      'A full and up-to-date terms of service of the Akuity Platform and Argo Enterprise services.',
    image: ''
  },
  'privacy-policy': {
    title: 'Akuity Privacy Policy',
    description:
      'A full and up-to-date privacy policy regarding how Akuity stores and uses your data.',
    image: ''
  },
  'case-studies': {
    cowbell: {
      title: 'Akuity and Cowbell Case Study',
      description:
        'Find out how Akuity helps Cowbell ease the operational burden of managing Argo CD at scale.',
      image: CowbellCaseStudyCoverImage
    },
    whatnot: {
      title: 'Akuity and Whatnot Case Study',
      description:
        'Find out how Akuity helps Whatnot ship often and focus on reliability',
      image: WhatnotCaseStudyCoverImage
    }
  }
};
