import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import { argoconPage } from '@/utils/contentData/argocon';

import './EventSchedule.scss';

const EventSchedule = () => {
  return (
    <div className="argocon-2022-event-schedule-at-glance__wrapper">
      <div className="argocon-2022-event-schedule-list">
        {argoconPage.eventSchedule.dayWise.map((info, _day) => {
          const day = _day + 1;

          const embedImageIdx = argoconPage.eventSchedule.embedImage.findIndex(
            (d) => d.onDay === day
          );

          const shouldEmbedImage = embedImageIdx !== -1;

          const embedImageData = shouldEmbedImage
            ? argoconPage.eventSchedule.embedImage[embedImageIdx]
            : null;

          return (
            <div key={_day} id={`argocon-2022-day-${day}`}>
              <Typography
                component="h4"
                typographyKind="header-secondary-large-bold"
                margin={[7, 0]}
              >
                Day {day}: {info.title}
              </Typography>

              <div className={`argocon-2022-day-${day}-talks`}>
                <ul>
                  {info.talks.map((talk) => (
                    <TalkListItem
                      key={talk.title}
                      title={talk.title}
                      authors={talk.authors}
                      scheduleUrl={talk.scheduleUrl}
                    />
                  ))}
                </ul>
                {shouldEmbedImage && (
                  <div className={embedImageData?.image.className}>
                    <Image
                      imageUrl={embedImageData?.image.url}
                      alt={embedImageData?.image.alt || 'Invalid image'}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}

        <div id="more-argo-details" className="mt-5">
          {argoconPage.workshopBenefits.map((paragraph, idx) => (
            <Typography
              key={idx}
              component="p"
              typographyKind="text-secondary-large-medium"
              size={20}
              lineHeight={32}
              margin={[8, 0]}
            >
              {paragraph}
            </Typography>
          ))}

          <Typography
            component="p"
            typographyKind="text-secondary-large-medium"
            size={20}
            lineHeight={32}
            margin={[8, 0]}
          >
            Learn how to:
          </Typography>

          <ul>
            {argoconPage.learnHowTo.map((listItem, idx) => (
              <Typography
                key={idx}
                component="li"
                typographyKind="text-secondary-large-medium"
                size={20}
                lineHeight={32}
                margin={[6, 0]}
              >
                {listItem}
              </Typography>
            ))}
          </ul>

          <Typography
            component="b"
            typographyKind="text-secondary-large-medium"
            size={20}
            lineHeight={32}
            margin={[8, 0]}
          >
            {argoconPage.workshopCompletion}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const TalkListItem = ({
  title,
  authors,
  scheduleUrl
}: {
  title: string;
  authors: string;
  scheduleUrl: string;
}) => (
  <li className="talk-list-item">
    <Typography
      component={'p'}
      typographyKind={'text-primary-extra-large-medium'}
      margin={[3, 0]}
      size={20}
    >
      <a href={scheduleUrl} target="__blank">
        {title}
      </a>{' '}
      - {authors}
    </Typography>
  </li>
);

export default EventSchedule;
