import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import '@/sections/About/components/Founders/Founders.scss';
import { argoconPage } from '@/utils/contentData/argocon';

import './Originator.scss';

const Originator = () => {
  return (
    <div className="hong-wang-akuity-founder" id="hong-wang-akuity-founder">
      <div className="founder-profile-container container-fluid">
        <div className="founder-profile-wrapper row">
          <div className="founder-profile__image col-md-12 col-xl-2">
            <Image
              imageUrl={argoconPage.originator.image.url}
              alt={argoconPage.originator.image.alt}
            />
          </div>
          <div className="founder-profile-text col-md-12 col-xl-10">
            <div className="founder-profile-text__description">
              {argoconPage.originator.history.map((paragraph) => (
                <Typography
                  key={paragraph.slice(0, 15)}
                  component="p"
                  typographyKind="text-secondary-large-medium"
                  size={16}
                  lineHeight={24}
                  margin={[5, 0]}
                >
                  {paragraph}
                </Typography>
              ))}
            </div>

            <div className="founder-profile-text__title">
              <Typography
                component="p"
                typographyKind="text-primary-medium-bold"
                size={18}
                lineHeight={32}
                margin={[5, 0]}
              >
                {argoconPage.originator.name}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Originator;
