import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import { argoconPage } from '@/utils/contentData/argocon';

import './EventDetails.scss';

const EventDetails = () => {
  return (
    <>
      <Typography
        className="argocon-header"
        component="h4"
        id="argocon-2022"
        typographyKind="header-secondary-large-bold"
        margin={[7, 0]}
      >
        {argoconPage.eventDetails.title}
      </Typography>

      <div className="argocon-thumbnail">
        <Image
          imageUrl={argoconPage.eventDetails.image.url}
          alt={argoconPage.eventDetails.image.alt}
        />
      </div>

      <div className="argocon-2022-event-details__wrapper d-flex justify-space-between mt-1 flex-wrap">
        <EventDetailBlock
          title="Dates"
          description={argoconPage.eventDetails.date.description}
        />
        <EventDetailBlock
          title="Location"
          description={argoconPage.eventDetails.location.description}
        />
        <EventDetailBlock
          title="Website"
          url={argoconPage.eventDetails.website.url}
        />
        <EventDetailBlock
          title="Schedule"
          url={argoconPage.eventDetails.schedule.url}
        />
      </div>

      <div className="argocon-akuity-diamond-sponsor">
        <Typography
          typographyKind="text-primary-large-bold"
          component="p"
          color="secondary-sixty"
        >
          {argoconPage.eventDetails.akuityRole}
        </Typography>
      </div>
    </>
  );
};

export const EventDetailBlock = ({
  title,
  description,
  url,
  boxClass
}: {
  title: string;
  description?: string;
  url?: string;
  boxClass?: string;
}) => {
  const wrapperClassname =
    'argocon-event-detail-block mt-5 d-flex align-items-center justify-content-center flex-column' +
    (boxClass ? ` ${boxClass}` : '');

  const Block = (
    <div className={url ? '' : wrapperClassname}>
      <Typography
        component="h3"
        color="#fff"
        typographyKind="header-secondary-medium"
        margin={[1, 0]}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          component="p"
          color="#fff"
          typographyKind="text-primary-medium-medium"
          size={16}
        >
          {description}
        </Typography>
      )}
    </div>
  );

  return url ? (
    <a className={wrapperClassname} href={url} target="__blank">
      {Block}
    </a>
  ) : (
    Block
  );
};

export default EventDetails;
