import { EventDetailBlock } from '../EventDetails';
import { TalkListItem } from '../EventSchedule';
import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import Argocon2024 from '@/static/assets/images/ArgoCon/argocon-2023-eu.svg';

import './ArgoConEU2024.scss';

export const ArgoConEU2024 = () => {
  return (
    <>
      <Typography
        className="argocon-2021-header"
        component="h4"
        id="argocon-2021"
        typographyKind="header-secondary-large-bold"
        margin={[7, 0]}
      >
        ArgoCon EU 2024
      </Typography>
      <div className="argocon-2024-thumbnail">
        <Image imageUrl={Argocon2024} alt="ArgoCon 2024 EU" />
      </div>
      <div
        className="justify-space-between mt-1 flex-wrap argoconna-2023-event-details"
        style={{ marginBottom: '48px' }}
      >
        <EventDetailBlock title="Date" description="19 March 2024" />
        <EventDetailBlock title="Location" description="Paris, France" />
        <EventDetailBlock
          title="Website"
          url="https://events.linuxfoundation.org/kubecon-cloudnativecon-europe/co-located-events/argocon/"
        />

        <EventDetailBlock
          title="Schedule"
          url="https://colocatedeventseu2024.sched.com/overview/type/ArgoCon"
        />
      </div>

      <Typography
        component="h4"
        typographyKind="header-secondary-large-bold"
        margin={[8, 0]}
      >
        Talks involving Akuity staff
      </Typography>
      <div className={`argocon-2022-day-1-talks`}>
        <ul>
        <TalkListItem
            title="Navigating Multi-stage Deployment Pipelines via the GitOps Approach"
            authors="Jesse Suen, Akuity"
            scheduleUrl="https://sched.co/1YK5O"
          />
          <TalkListItem
            title="Panel: Expanding Argo CD Content Management Using OCI Artifacts"
            authors="Christian Hernandez, Akuity; Hillary Lipsig, Andrew Block, Red Hat; Dan Garfield, Codefresh"
            scheduleUrl="https://sched.co/1YFea"
          />
          <TalkListItem
            title="Why Is It Taking so Long?! Shining the Light on Application Syncs in Argo CD with Keptn" 
            authors="Christian Hernandez, Akuity & Andreas Grabner, Dynatrace"
            scheduleUrl="https://sched.co/1YFh1"
          />
          <TalkListItem
            title="The Rendered Manifests Pattern: Reveal Your True Desired State"
            authors="Nicholas Morey, Akuity"
            scheduleUrl="https://sched.co/1YFhq"
          />
          <TalkListItem
            title="Supercharging Argo CD’s Manifest Generation Capabilities (KubeCon talk)"
            authors="Alexander Matyushentsev, Akuity; Leonardo Luz Almeida, Intuit"
            scheduleUrl="https://sched.co/1Yhfc"
          />
          <TalkListItem
            title="Tutorial: Progressive Delivery with Argo Rollouts (KubeCon talk)"
            authors="Christian Hernandez, Akuity; Kostis Kapelonis, Codefresh; Harriet Lawrence, Kevin Dubois, Natale Vinto, Red Hat"
            scheduleUrl="https://sched.co/1YeSd"
          />
        </ul>
      </div>
    </>
  );
};
